exports.components = {
  "component---src-components-category-tsx": () => import("./../../../src/components/Category.tsx" /* webpackChunkName: "component---src-components-category-tsx" */),
  "component---src-components-single-single-tsx": () => import("./../../../src/components/Single/Single.tsx" /* webpackChunkName: "component---src-components-single-single-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-o-nama-tsx": () => import("./../../../src/pages/o-nama.tsx" /* webpackChunkName: "component---src-pages-o-nama-tsx" */),
  "component---src-pages-politika-privatnosti-tsx": () => import("./../../../src/pages/politika-privatnosti.tsx" /* webpackChunkName: "component---src-pages-politika-privatnosti-tsx" */),
  "component---src-pages-pravila-koriscenja-tsx": () => import("./../../../src/pages/pravila-koriscenja.tsx" /* webpackChunkName: "component---src-pages-pravila-koriscenja-tsx" */)
}

